import React, { useState, useEffect, useRef, Fragment } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import sampleSize from "lodash.samplesize"
import shuffle from "lodash.shuffle"
import { format } from "date-fns"
import useScrollPosition from "@react-hook/window-scroll"
import interpolate from "color-interpolate"

/* Import Global Components */
import SEO from "~components/seo"
import Page from "~components/page"

/* Import Local Components */
import Keywords from "./components/keywords/_keywords"
import Portals from "./components/portals/_portals"
import Mailchimp from "./components/mailchimp/_mailchimp"
import Quotation from "./components/quotation/_quotation"

/* Import Local Style(s) */
import "./about.css"

// Letter Serializers
const serializers = {
  types: {
    portals: ({ node: { images: portals } }) => <Portals portals={portals} />,
    keywords: () => <Keywords />,
  },
  marks: {
    timestamp: () => <span>{format(new Date(), "MMMM Do, yyyy")}</span>,
  },
}

const About = ({ data: { sanityAbout: about } }) => {
  const scrollY = useScrollPosition()
  const [sampledQuotations] = useState(shuffle(sampleSize(about.quotations, 3)))
  const introElementReference = useRef()

  // on page position change, interpolate background color for intro section
  useEffect(() => {
    // goes from: --color-text: #1E0E8C;
    // to: --color-blue-4: #120864;
    // scroll % is from top of window, to bottom of about section
    const colorMap = interpolate(["#1E0E8C", "#120864"])
    if (scrollY && introElementReference && introElementReference.current) {
      const percentageScrolled =
        scrollY / introElementReference.current.scrollHeight
      introElementReference.current.style.setProperty(
        "--color-background",
        colorMap(percentageScrolled)
      )
    }
  }, [scrollY, introElementReference])

  return (
    <Fragment>
      <SEO title="About" />
      <Page page="about">
        {/* <Header about={true} /> */}
        <div className="content">
          {/* INTRO LETTER */}
          <div className="intro-wrapper">
            <div className="quotations">
              <Quotation quotation={sampledQuotations[0]} alignment="left" />
            </div>
            <div className="container intro" ref={introElementReference}>
              <BlockContent
                blocks={about._rawLetter}
                serializers={serializers}
              />
            </div>
            <div className="quotations">
              <Quotation quotation={sampledQuotations[1]} alignment="right" />
              <Quotation quotation={sampledQuotations[2]} alignment="right" />
            </div>
          </div>
          {/* SEE ALSO */}
          <div className="container see-also">
            <p>See also:</p>
            <ul>
              {about.pdfs.map(pdf => {
                return (
                  <li key={pdf._key}>
                    <a href={pdf.asset.url} target="_blank" rel="noreferrer">
                      {pdf.title}
                    </a>
                  </li>
                )
              })}
            </ul>
            {about._rawContactUponRequest && (
              <p>
                <BlockContent blocks={about._rawContactUponRequest} />
              </p>
            )}
          </div>
          <div className="container contact">
            <p>Contact</p>
            {/* image */}
            <div className="image">
              <Img
                fluid={about.contactPhoto.asset.fluid}
                backgroundColor={"rgba(64, 78, 235, 0.12)"}
              />
            </div>
            {/*  */}
            {/* contact header */}
            <div
              style={{
                marginBottom: "1.5rem",
                lineHeight: "var(--font-line-height-large)",
              }}
            >
              <p className="contact-header">{about.contactHeader}</p>
            </div>
            {/* contact copy */}
            <div className="contact-copy">
              <BlockContent blocks={about._rawContactDescription} />
            </div>
            {/* instagram handle */}
            <div className="instagram">
              <p className="small">
                For ongoing thought experiments, visit{" "}
                <a
                  href={`https://www.instagram.com/${about.instagramHandle}`}
                >{`@${about.instagramHandle}`}</a>{" "}
                on Instagram.
              </p>
            </div>
            <p className="small">
              Please join the mailing list for intermittent emails about
              upcoming projects:
            </p>
            {/* mailing list */}
            <Mailchimp id={about.mailingListId} />
          </div>
          <div className="container credits">
            <p style={{ fontWeight: "bold" }}>Credits</p>
            <BlockContent blocks={about._rawCredits} />
          </div>
          <div className="container faq">
            <div className="image">
              <Img
                fluid={about.faqPhoto.asset.fluid}
                backgroundColor={"var(--color-white)"}
              />
            </div>
            <p style={{ fontWeight: "bold" }}>FAQ</p>
            <BlockContent blocks={about._rawFaq} />
          </div>
        </div>
      </Page>
    </Fragment>
  )
}

export default About

export const query = graphql`
  query AboutPageQuery {
    sanityAbout(_id: { eq: "about" }) {
      id
      _rawLetter(resolveReferences: { maxDepth: 10 })
      pdfs {
        title
        _key
        asset {
          url
        }
      }
      _rawContactUponRequest
      contactPhoto {
        asset {
          fluid {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      contactHeader
      _rawContactDescription
      mailingListUrl
      _rawCredits
      faqPhoto {
        asset {
          fluid {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      instagramHandle
      _rawFaq
      quotations {
        author
        _key
        _rawQuotation
      }
    }
  }
`
