import React, { useState } from "react"

import "./mailchimp.css"

const Mailchimp = ({
  action = "https://coriolinghouse.us9.list-manage.com/subscribe/post?u=6dc81f59c46a5c0625fa536ac&amp;id=181a896fba",
}) => {
  const [emailAddress, setEmailAddress] = useState("")
  const handleChange = event => {
    setEmailAddress(event.target.value)
  }

  return (
    <div id="mc_embed_signup">
      <form
        action={action}
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate
      >
        <div id="mc_embed_signup_scroll">
          <div className="mc-field-group">
            <input
              type="email"
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
              value={emailAddress}
              onChange={handleChange}
            />
          </div>
          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_9762ef39ce8627ca59cd94e1d_45b294d208"
              tabIndex="-1"
              value=""
              onChange={() => {}}
            />
          </div>
          <div className="clear">
            <input
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button"
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default Mailchimp
