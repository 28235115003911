import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import classnames from "classnames"

import "./quotation.css"

const Quotation = ({ quotation, alignment }) => (
  <div className={classnames("quotation", alignment)}>
    <BlockContent blocks={quotation._rawQuotation} />
    <p>— {quotation.author}</p>
  </div>
)

export default Quotation
