import React from "react"
// import Img from "gatsby-image"

import "./portals.css"

const Portals = ({ portals }) => {
  return (
    <div className="portals">
      {portals.map(portal => (
        <img key={portal._key} src={portal.asset.url} alt="Portal" />
      ))}
    </div>
  )
}

export default Portals
