import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import slug from "slug"

const Keywords = () => {
  const {
    allSanityKeyword: { nodes: keywords },
  } = useStaticQuery(graphql`
    query KeywordComponentQuery {
      allSanityKeyword {
        nodes {
          title
          id
        }
      }
    }
  `)

  return (
    <ul className="keywords">
      {keywords.map(keyword => (
        <li key={keyword.id}>
          <Link to={`/keyword/${slug(keyword.title, { lower: true })}`}>
            {keyword.title}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default Keywords
